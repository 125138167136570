import React from 'react'
import Layout from '../components/layout'

const PrivacyPolicyPage = () => (
  <Layout>
    <h1>PRIVACY POLICY</h1>
    <p>
      <b>Last updated July 10, 2023</b>
    </p>
    <p>
      This privacy notice for Ehya, describes how and why we might collect,
      store, use, and/or share your information when you use our services, such
      as when you:
    </p>
    <ul>
      <li>
        Download and use our mobile application (Ehya | إحياء) or any other
        application of ours that links to this privacy notice
      </li>
      <li>
        Engage with us in other related ways, including any sales, marketing, or
        events
      </li>
    </ul>

    <p>
      <b>Questions or concerns? </b>
      Reading this privacy notice will help you understand your privacy rights
      and choices. If you do not agree with our policies and practices, please
      do not use our Services. If you still have any questions or concerns,
      please contact us at{' '}
      <a href="mailto:contact@eillia.com">contact@eillia.com</a>
    </p>
    <br></br>
    <h2>SUMMARY OF KEY POINTS</h2>
    <p>
      <b>
        <em>This summary provides key points from our privacy notice.</em>
      </b>
    </p>
    <p>
      <b>What personal information do we process? </b>
      We do not process your personal information when you use our application.
      It's stored in the device locally and used for app functionality.
    </p>
    <p>
      <b>Do we process any sensitive personal information? </b>
      We do not process sensitive personal information.
    </p>
    <p>
      <b>Do we receive any information from third parties? </b>
      We do not receive any information from third parties.
    </p>

    <p>
      <b>How do we process your information? </b>
      We do not process your personal information for any commercial use.
      However, we gather some information to improve the application overall
      experience. For example, we use the device model to detect app crashes.
    </p>

    <p>
      <b>
        In what situations and with which parties do we share personal
        information?{' '}
      </b>
      We do not share information with third parties.
    </p>

    <p>
      <b>How do we keep your information safe? </b>
      We have organizational and technical processes and procedures in place to
      protect your personal information. However, no electronic transmission
      over the internet or information storage technology can be guaranteed to
      be 100% secure, so we cannot promise or guarantee that hackers,
      cybercriminals, or other unauthorized third parties will not be able to
      defeat our security and improperly collect, access, steal, or modify your
      information.
    </p>

    <p>
      <b>What are your rights? </b>
      Depending on where you are located geographically, the applicable privacy
      law may mean you have certain rights regarding your personal information.
    </p>

    <p>
      <b>How do you exercise your rights? </b>
      The easiest way to exercise your rights is by emailing us on{' '}
      <a href="mailto:contact@eillia.com">contact@eillia.com</a>, or by
      contacting us. We will consider and act upon any request in accordance
      with applicable data protection laws.
    </p>
    <br></br>
    <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
    <h3>Personal information you disclose to us</h3>
    <p>
      <em>
        <b> In Short: </b>
        We collect personal information that you provide to us.
      </em>
    </p>

    <p>
      We collect personal information that you voluntarily provide to us when
      you express an interest in obtaining information about us or our products
      and Services, when you participate in activities on the Services, or
      otherwise when you contact us.
    </p>

    <p>
      <b>Sensitive Information. </b>
      We do not process sensitive information.
    </p>

    <p>
      <b>Application Data. </b>
      If you use our application(s), we also may collect the following
      information if you choose to provide us with access or permission:
    </p>
    <ul>
      <li>
        <em>Mobile Device Data.</em>
        We automatically collect device information (such as your mobile device
        ID, model, and manufacturer), operating system, version information and
        system configuration information, device and application identification
        numbers, browser type and version, hardware model Internet service
        provider and/or mobile carrier, and Internet Protocol (IP) address (or
        proxy server). If you are using our application(s), we may also collect
        information about the phone network associated with your mobile device,
        your mobile device’s operating system or platform, the type of mobile
        device you use, your mobile device’s unique device ID, and information
        about the features of our application(s) you accessed.
      </li>
      <li>
        <em>Push Notifications.</em>
        We may request to send you push notifications regarding your account or
        certain features of the application(s). If you wish to opt out from
        receiving these types of communications, you may turn them off in your
        device's settings.
      </li>
    </ul>
    <p>
      This information is primarily needed to maintain the security and
      operation of our application(s), for troubleshooting, and for our internal
      analytics and reporting purposes.
    </p>
    <p>
      All personal information that you provide to us must be true, complete,
      and accurate, and you must notify us of any changes to such personal
      information.
    </p>

    <h3>Information automatically collected</h3>
    <p>
      <em>
        <b> In Short: </b>
        Some information — such as your Internet Protocol (IP) address and/or
        browser and device characteristics — is collected automatically when you
        visit our Services/Applications.
      </em>
    </p>
    <p>
      We automatically collect certain information when you visit, use, or
      navigate the Services. This information does not reveal your specific
      identity (like your name or contact information) but may include device
      and usage information, such as your IP address, browser and device
      characteristics, operating system, language preferences, referring URLs,
      device name, country, location, information about how and when you use our
      Services, and other technical information. This information is primarily
      needed to maintain the security and operation of our Services, and for our
      internal analytics and reporting purposes.
    </p>

    <p>The information we collect includes: </p>
    <ul>
      <li>
        <em>Log and Usage Data.</em>
        Log and usage data is service-related, diagnostic, usage, and
        performance information our servers automatically collect when you
        access or use our Services and which we record in log files. Depending
        on how you interact with us, this log data may include your IP address,
        device information, browser type, and settings and information about
        your activity in the Services (such as the date/time stamps associated
        with your usage, pages and files viewed, searches, and other actions you
        take such as which features you use), device event information (such as
        system activity, error reports (sometimes called "crash dumps"), and
        hardware settings).
      </li>
      <li>
        <em>Device Data.</em>
        We collect device data such as information about your computer, phone,
        tablet, or other device you use to access the Services. Depending on the
        device used, this device data may include information such as your IP
        address (or proxy server), device and application identification
        numbers, location, browser type, hardware model, Internet service
        provider and/or mobile carrier, operating system, and system
        configuration information.
      </li>
    </ul>

    <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
    <p>
      <em>
        <b> In Short: </b>
        We process your information to provide, improve, and administer our
        Services, communicate with you, for security and fraud prevention, and
        to comply with law. We may also process your information for other
        purposes with your consent.
      </em>
    </p>
    <p>
      We process your personal information for a variety of reasons, depending
      on how you interact with our Services, including:
    </p>
    <ul>
      <li>
        <b>To save or protect an individual's vital interest.</b>
        We may process your information when necessary to save or protect an
        individual’s vital interest, such as to prevent harm.
      </li>
    </ul>

    <h2>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
    <p>
      <em>
        <b> In Short: </b>
        We only process your personal information when we believe it is
        necessary and we have a valid legal reason (i.e., legal basis) to do so
        under applicable law, like with your consent, to comply with laws, to
        provide you with services to enter into or fulfill our contractual
        obligations, to protect your rights, or to fulfill our legitimate
        business interests.
      </em>
    </p>
    <p>
      <em>
        <u>If you are located in the EU or UK, this section applies to you.</u>
      </em>
    </p>
    <p>
      The General Data Protection Regulation (GDPR) and UK GDPR require us to
      explain the valid legal bases we rely on in order to process your personal
      information. As such, we may rely on the following legal bases to process
      your personal information:
    </p>
    <ul>
      <li>
        <b>Consent.</b>
        We may process your information if you have given us permission (i.e.,
        consent) to use your personal information for a specific purpose. You
        can withdraw your consent at any time.
      </li>
      <li>
        <b>Legal Obligations.</b>
        We may process your information where we believe it is necessary for
        compliance with our legal obligations, such as to cooperate with a law
        enforcement body or regulatory agency, exercise or defend our legal
        rights, or disclose your information as evidence in litigation in which
        we are involved.
      </li>
      <li>
        <b>Vital Interests.</b>
        We may process your information where we believe it is necessary to
        protect your vital interests or the vital interests of a third party,
        such as situations involving potential threats to the safety of any
        person.
      </li>
    </ul>
    <p>
      <em>
        <u>If you are located in Canada, this section applies to you.</u>
      </em>
    </p>
    <p>
      We may process your information if you have given us specific permission
      (i.e., express consent) to use your personal information for a specific
      purpose, or in situations where your permission can be inferred (i.e.,
      implied consent). You can withdraw your consent at any time.
    </p>
    <p>
      In some exceptional cases, we may be legally permitted under applicable
      law to process your information without your consent, including, for
      example:
    </p>
    <ul>
      <li>
        If collection is clearly in the interests of an individual and consent
        cannot be obtained in a timely way
      </li>
      <li>For investigations and fraud detection and prevention</li>
      <li>For business transactions provided certain conditions are met</li>
      <li>
        If it is contained in a witness statement and the collection is
        necessary to assess, process, or settle an insurance claim
      </li>
      <li>
        For identifying injured, ill, or deceased persons and communicating with
        next of kin
      </li>
      <li>
        If we have reasonable grounds to believe an individual has been, is, or
        may be victim of financial abuse
      </li>
      <li>
        If it is reasonable to expect collection and use with consent would
        compromise the availability or the accuracy of the information and the
        collection is reasonable for purposes related to investigating a breach
        of an agreement or a contravention of the laws of Canada or a province
      </li>
      <li>
        If disclosure is required to comply with a subpoena, warrant, court
        order, or rules of the court relating to the production of records
      </li>
      <li>
        If it was produced by an individual in the course of their employment,
        business, or profession and the collection is consistent with the
        purposes for which the information was produced
      </li>
      <li>
        If the collection is solely for journalistic, artistic, or literary
        purposes
      </li>
      <li>
        If the information is publicly available and is specified by the
        regulations
      </li>
    </ul>

    <h2>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
    <p>
      <em>
        <b> In Short: </b>
        We do not share personal information in any specific situations with the
        any third parties.
      </em>
    </p>

    <h2>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
    <p>
      <em>
        <b> In Short: </b>
        We keep your information for as long as necessary to fulfill the
        purposes outlined in this privacy notice unless otherwise required by
        law.
      </em>
    </p>
    <p>
      We will only keep your personal information for as long as it is necessary
      for the purposes set out in this privacy notice, unless a longer retention
      period is required or permitted by law (such as tax, accounting, or other
      legal requirements).
    </p>
    <p>
      When we have no ongoing legitimate business need to process your personal
      information, we will either delete or anonymize such information, or, if
      this is not possible (for example, because your personal information has
      been stored in backup archives), then we will securely store your personal
      information and isolate it from any further processing until deletion is
      possible.
    </p>

    <h2>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
    <p>
      <em>
        <b> In Short: </b>
        We aim to protect your personal information through a system of
        organizational and technical security measures.
      </em>
    </p>
    <p>
      We have implemented appropriate and reasonable technical and
      organizational security measures designed to protect the security of any
      personal information we process. However, despite our safeguards and
      efforts to secure your information, no electronic transmission over the
      Internet or information storage technology can be guaranteed to be 100%
      secure, so we cannot promise or guarantee that hackers, cybercriminals, or
      other unauthorized third parties will not be able to defeat our security
      and improperly collect, access, steal, or modify your information.
      Although we will do our best to protect your personal information,
      transmission of personal information to and from our Services is at your
      own risk. You should only access the Services within a secure environment.
    </p>
    <h2>7. DO WE COLLECT INFORMATION FROM MINORS?</h2>
    <p>
      <em>
        <b> In Short: </b>
        We do not knowingly collect data from or market to children under 18
        years of age.
      </em>
    </p>
    <p>
      We do not knowingly solicit data from or market to children under 18 years
      of age. By using the Services, you represent that you are at least 18 or
      that you are the parent or guardian of such a minor and consent to such
      minor dependent’s use of the Services. If we learn that personal
      information from users less than 18 years of age has been collected, we
      will deactivate the account and take reasonable measures to promptly
      delete such data from our records. If you become aware of any data we may
      have collected from children under age 18, please contact us at{' '}
      <a href="mailto:contact@eillia.com">contact@eillia.com</a>
    </p>

    <h2>8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
    <p>
      <em>
        <b> In Short: </b>
        In some regions, such as the European Economic Area (EEA), United
        Kingdom (UK), and Canada , you have rights that allow you greater access
        to and control over your personal information. You may review, change,
        or terminate your account at any time.
      </em>
    </p>
    <p>
      In some regions (like the EEA, UK, and Canada ), you have certain rights
      under applicable data protection laws. These may include the right (i) to
      request access and obtain a copy of your personal information, (ii) to
      request rectification or erasure; (iii) to restrict the processing of your
      personal information; and (iv) if applicable, to data portability. In
      certain circumstances, you may also have the right to object to the
      processing of your personal information. You can make such a request by
      contacting us by using the contact details provided in the section "HOW
      CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
    </p>
    <p>
      We will consider and act upon any request in accordance with applicable
      data protection laws.
    </p>

    <p>
      If you are located in the EEA or UK and you believe we are unlawfully
      processing your personal information, you also have the right to complain
      to your Member State data protection authority or UK data protection
      authority.
    </p>

    <p>
      <em>
        <u>Withdrawing your consent:</u>
      </em>
      If we are relying on your consent to process your personal information,
      which may be express and/or implied consent depending on the applicable
      law, you have the right to withdraw your consent at any time. You can
      withdraw your consent at any time by contacting us by using the contact
      details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS
      NOTICE?" below.
    </p>

    <p>
      However, please note that this will not affect the lawfulness of the
      processing before its withdrawal nor, when applicable law allows, will it
      affect the processing of your personal information conducted in reliance
      on lawful processing grounds other than consent.
    </p>

    <p>
      If you have questions or comments about your privacy rights, you may email
      us at <a href="mailto:contact@eillia.com">contact@eillia.com</a>
    </p>

    <h2>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
    <p>
      Most web browsers and some mobile operating systems and mobile
      applications include a Do-Not-Track ("DNT") feature or setting you can
      activate to signal your privacy preference not to have data about your
      online browsing activities monitored and collected. At this stage no
      uniform technology standard for recognizing and implementing DNT signals
      has been finalized . As such, we do not currently respond to DNT browser
      signals or any other mechanism that automatically communicates your choice
      not to be tracked online. If a standard for online tracking is adopted
      that we must follow in the future, we will inform you about that practice
      in a revised version of this privacy notice.
    </p>

    <h2>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
    <p></p>
    <p>
      California Civil Code Section 1798.83, also known as the "Shine The Light"
      law, permits our users who are California residents to request and obtain
      from us, once a year and free of charge, information about categories of
      personal information (if any) we disclosed to third parties for direct
      marketing purposes and the names and addresses of all third parties with
      which we shared personal information in the immediately preceding calendar
      year. If you are a California resident and would like to make such a
      request, please submit your request in writing to us using the contact
      information provided below.
    </p>

    <p>
      If you are under 18 years of age, reside in California, and have a
      registered account with Services, you have the right to request removal of
      unwanted data that you publicly post on the Services. To request removal
      of such data, please contact us using the contact information provided
      below and include the email address associated with your account and a
      statement that you reside in California. We will make sure the data is not
      publicly displayed on the Services, but please be aware that the data may
      not be completely or comprehensively removed from all our systems (e.g.,
      backups, etc.).
    </p>

    <h3>CCPA Privacy Notice</h3>
    <p>The California Code of Regulations defines a "resident" as: </p>
    <ol>
      <li>
        Every individual who is in the State of California for other than a
        temporary or transitory purpose and
      </li>
      <li>
        Every individual who is domiciled in the State of California who is
        outside the State of California for a temporary or transitory purpose
      </li>
    </ol>

    <p>All other individuals are defined as "non-residents".</p>
    <p>
      If this definition of "resident" applies to you, we must adhere to certain
      rights and obligations regarding your personal information.
    </p>
    <h4>What categories of personal information do we collect?</h4>

    <p>
      We have collected the following categories of personal information in the
      past twelve (12) months:
    </p>

    <table>
      <tr>
        <td>
          <b>Category</b>
        </td>
        <td>
          <b>Examples</b>
        </td>
        <td>
          <b>Collected</b>
        </td>
      </tr>
      <tr>
        <td>A. Identifiers </td>
        <td>
          Contact details, such as real name, alias, postal address, telephone
          or mobile contact number, unique personal identifier, online
          identifier, Internet Protocol address, email address, and account name
        </td>
        <td>NO</td>
      </tr>

      <tr>
        <td>
          B. Personal information categories listed in the California Customer
          Records statute
        </td>
        <td>
          Name, contact information, education, employment, employment history,
          and financial information
        </td>
        <td>NO</td>
      </tr>

      <tr>
        <td>
          C. Protected classification characteristics under California or
          federal law
        </td>
        <td>Gender and date of birth</td>
        <td>NO</td>
      </tr>

      <tr>
        <td>D. Commercial information</td>
        <td>
          Transaction information, purchase history, financial details, and
          payment information
        </td>
        <td>NO</td>
      </tr>

      <tr>
        <td>E. Biometric information</td>
        <td>Fingerprints and voiceprints</td>
        <td>NO</td>
      </tr>

      <tr>
        <td>F. Internet or other similar network activity</td>
        <td>
          Browsing history, search history, online behavior , interest data, and
          interactions with our and other websites, applications, systems, and
          advertisements
        </td>
        <td>NO</td>
      </tr>

      <tr>
        <td>G. Geolocation data</td>
        <td>Device location</td>
        <td>NO</td>
      </tr>

      <tr>
        <td>
          H. Audio, electronic, visual, thermal, olfactory, or similar
          information
        </td>
        <td>
          Images and audio, video or call recordings created in connection with
          our business activities
        </td>
        <td>NO</td>
      </tr>

      <tr>
        <td>I. Professional or employment-related information</td>
        <td>
          Business contact details in order to provide you our Services at a
          business level or job title, work history, and professional
          qualifications if you apply for a job with us
        </td>
        <td>NO</td>
      </tr>

      <tr>
        <td>J. Education Information</td>
        <td>Student records and directory information</td>
        <td>NO</td>
      </tr>

      <tr>
        <td>K. Inferences drawn from other personal information</td>
        <td>
          Inferences drawn from any of the collected personal information listed
          above to create a profile or summary about, for example, an
          individual’s preferences and characteristics
        </td>
        <td>NO</td>
      </tr>

      <tr>
        <td>L. Sensitive Personal Information</td>
        <td> </td>
        <td>NO</td>
      </tr>
    </table>

    <p>
      We may also collect other personal information outside of these categories
      through instances where you interact with us in person, online, or by
      phone or mail in the context of:
    </p>

    <ul>
      <li>Receiving help through our customer support channels;</li>
      <li>Participation in customer surveys or contests; and</li>
      <li>
        Facilitation in the delivery of our Services and to respond to your
        inquiries.
      </li>
    </ul>

    <h4>How do we use and share your personal information?</h4>
    <p>
      More information about our data collection and sharing practices can be
      found in this privacy notice.
    </p>
    <p>
      You may contact us by email at{' '}
      <a href="mailto:contact@eillia.com">contact@eillia.com</a>, or by
      referring to the contact details at the bottom of this page.
    </p>

    <p>
      If you are using an authorized agent to exercise your right to opt out we
      may deny a request if the authorized agent does not submit proof that they
      have been validly authorized to act on your behalf.
    </p>

    <h4>Will your information be shared with anyone else?</h4>
    <p>
      We may disclose your personal information with our service providers
      pursuant to a written contract between us and each service provider. Each
      service provider is a for-profit entity that processes the information on
      our behalf, following the same strict privacy protection obligations
      mandated by the CCPA.
    </p>

    <p>
      We may use your personal information for our own business purposes, such
      as for undertaking internal research for technological development and
      demonstration. This is not considered to be "selling" of your personal
      information.
    </p>

    <p>
      We have not disclosed, sold, or shared any personal information to third
      parties for a business or commercial purpose in the preceding twelve (12)
      months. We will not sell or share personal information in the future
      belonging to website visitors, users, and other consumers.
    </p>

    <h4>Your rights with respect to your personal data</h4>

    <p>
      <u>Right to request deletion of the data — Request to delete</u>
    </p>

    <p>
      You can ask for the deletion of your personal information. If you ask us
      to delete your personal information, we will respect your request and
      delete your personal information, subject to certain exceptions provided
      by law, such as (but not limited to) the exercise by another consumer of
      his or her right to free speech, our compliance requirements resulting
      from a legal obligation, or any processing that may be required to protect
      against illegal activities.
    </p>

    <p>
      <u>Right to be informed — Request to know </u>
    </p>
    <p>Depending on the circumstances, you have a right to know:</p>

    <ul>
      <li>whether we collect and use your personal information;</li>
      <li>the categories of personal information that we collect;</li>
      <li>
        the purposes for which the collected personal information is used;
      </li>
      <li>whether we sell or share personal information to third parties;</li>
      <li>
        the categories of personal information that we sold, shared, or
        disclosed for a business purpose;
      </li>
      <li>
        the categories of third parties to whom the personal information was
        sold, shared, or disclosed for a business purpose;
      </li>
      <li>
        the business or commercial purpose for collecting, selling, or sharing
        personal information; and
      </li>
      <li>
        the specific pieces of personal information we collected about you.
      </li>
    </ul>

    <p>
      In accordance with applicable law, we are not obligated to provide or
      delete consumer information that is de-identified in response to a
      consumer request or to re-identify individual data to verify a consumer
      request.
    </p>

    <p>
      <u>
        <u>
          Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
          Rights
        </u>
      </u>
    </p>

    <p>
      We will not discriminate against you if you exercise your privacy rights.
    </p>

    <p>
      <u>Right to Limit Use and Disclosure of Sensitive Personal Information</u>
    </p>

    <p>We do not process consumer's sensitive personal information.</p>

    <p>
      <u>Verification process</u>
    </p>

    <p>
      Upon receiving your request, we will need to verify your identity to
      determine you are the same person about whom we have the information in
      our system. These verification efforts require us to ask you to provide
      information so that we can match it with information you have previously
      provided us. For instance, depending on the type of request you submit, we
      may ask you to provide certain information so that we can match the
      information you provide with the information we already have on file, or
      we may contact you through a communication method (e.g., phone or email)
      that you have previously provided to us. We may also use other
      verification methods as the circumstances dictate.
    </p>

    <p>
      We will only use personal information provided in your request to verify
      your identity or authority to make the request. To the extent possible, we
      will avoid requesting additional information from you for the purposes of
      verification. However, if we cannot verify your identity from the
      information already maintained by us, we may request that you provide
      additional information for the purposes of verifying your identity and for
      security or fraud-prevention purposes. We will delete such additionally
      provided information as soon as we finish verifying you.
    </p>

    <p>
      <u>Other privacy rights</u>
    </p>
    <p>
      <ul>
        <li>You may object to the processing of your personal information.</li>
        <li>
          You may request correction of your personal data if it is incorrect or
          no longer relevant, or ask to restrict the processing of the
          information.
        </li>
        <li>
          You can designate an authorized agent to make a request under the CCPA
          on your behalf. We may deny a request from an authorized agent that
          does not submit proof that they have been validly authorized to act on
          your behalf in accordance with the CCPA.
        </li>
        <li>
          You may request to opt out from future selling or sharing of your
          personal information to third parties. Upon receiving an opt-out
          request, we will act upon the request as soon as feasibly possible,
          but no later than fifteen (15) days from the date of the request
          submission.
        </li>
      </ul>
    </p>
    <p>
      To exercise these rights, you can contact us by email at{' '}
      <a href="mailto:contact@eillia.com">contact@eillia.com</a>, or by
      referring to the contact details at the bottom of this document. If you
      have a complaint about how we handle your data, we would like to hear from
      you.
    </p>
    <h2>11. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
    <p>
      <em>
        <b> In Short: </b>
        Yes, if you are a resident of Virginia, you may be granted specific
        rights regarding access to and use of your personal information.
      </em>
    </p>

    <h3>Virginia CDPA Privacy Notice</h3>
    <p>Under the Virginia Consumer Data Protection Act (CDPA):</p>
    <p>
      "Consumer" means a natural person who is a resident of the Commonwealth
      acting only in an individual or household context. It does not include a
      natural person acting in a commercial or employment context.
    </p>

    <p>
      "Personal data" means any information that is linked or reasonably
      linkable to an identified or identifiable natural person. "Personal data"
      does not include de-identified data or publicly available information.
    </p>

    <p>
      "Sale of personal data" means the exchange of personal data for monetary
      consideration.
    </p>
    <p>
      If this definition "consumer" applies to you, we must adhere to certain
      rights and obligations regarding your personal data.
    </p>
    <p>
      The information we collect, use, and disclose about you will vary
      depending on how you interact with us and our Services. To find out more,
      please visit the following links:
    </p>

    <p>
      <ul>
        <li>Personal data we collect</li>
        <li>How we use your personal data</li>
        <li>When and with whom we share your personal data</li>
      </ul>
    </p>

    <p>
      <u>Your rights with respect to your personal data</u>
    </p>

    <p>
      <ul>
        <li>
          Right to be informed whether or not we are processing your personal
          data
        </li>
        <li>Right to access your personal data</li>
        <li>Right to correct inaccuracies in your personal data</li>
        <li>Right to request deletion of your personal data</li>
        <li>
          Right to obtain a copy of the personal data you previously shared with
          us
        </li>
        <li>
          Right to opt out of the processing of your personal data if it is used
          for targeted advertising, the sale of personal data, or profiling in
          furtherance of decisions that produce legal or similarly significant
          effects ("profiling")
        </li>
      </ul>
    </p>
    <p>
      We have not sold any personal data to third parties for business or
      commercial purposes. We will not sell personal data in the future
      belonging to website visitors, users, and other consumers.
    </p>

    <p>
      <u>Exercise your rights provided under the Virginia CDPA</u>
    </p>

    <p>
      More information about our data collection and sharing practices can be
      found in this privacy notice.
    </p>
    <p>
      You may contact us by email at
      <a href="mailto:contact@eillia.com">contact@eillia.com</a>.
    </p>
    <p>
      If you are using an authorized agent to exercise your rights, we may deny
      a request if the authorized agent does not submit proof that they have
      been validly authorized to act on your behalf.
    </p>

    <p>
      <u>Verification process</u>
    </p>
    <p>
      We may request that you provide additional information reasonably
      necessary to verify you and your consumer's request. If you submit the
      request through an authorized agent, we may need to collect additional
      information to verify your identity before processing your request.
    </p>

    <p>
      Upon receiving your request, we will respond without undue delay, but in
      all cases, within forty-five (45) days of receipt. The response period may
      be extended once by forty-five (45) additional days when reasonably
      necessary. We will inform you of any such extension within the initial
      45-day response period, together with the reason for the extension.
    </p>

    <p>
      <u>Right to appeal</u>
    </p>

    <p>
      If we decline to take action regarding your request, we will inform you of
      our decision and reasoning behind it. If you wish to appeal our decision,
      please email us at
      <a href="mailto:contact@eillia.com">contact@eillia.com</a>. Within sixty
      (60) days of receipt of an appeal, we will inform you in writing of any
      action taken or not taken in response to the appeal, including a written
      explanation of the reasons for the decisions. If your appeal if denied,
      you may contact the{' '}
      <a
        href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
        target="_blank"
      >
        Attorney General to submit a complaint
      </a>
      .
    </p>

    <h2>12. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
    <p>
      <em>
        <b> In Short: </b>
        Yes, we will update this notice as necessary to stay compliant with
        relevant laws.
      </em>
    </p>

    <p>
      We may update this privacy notice from time to time. The updated version
      will be indicated by an updated "Revised" date and the updated version
      will be effective as soon as it is accessible. If we make material changes
      to this privacy notice, we may notify you either by prominently posting a
      notice of such changes or by directly sending you a notification. We
      encourage you to review this privacy notice frequently to be informed of
      how we are protecting your information.
    </p>

    <h2>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
    <p>
      If you have questions or comments about this notice, you may email us at{' '}
      <a href="mailto:contact@eillia.com">contact@eillia.com</a>.
    </p>

    <h2>
      14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
    </h2>
    <p>
      Based on the applicable laws of your country, you may have the right to
      request access to the personal information we collect from you, change
      that information, or delete it. To request to review, update, or delete
      your personal information, please email us at{' '}
      <a href="mailto:contact@eillia.com">contact@eillia.com</a>.
    </p>
  </Layout>
)

export default PrivacyPolicyPage
